.Scrollbar::-webkit-scrollbar {
    width: 7px;
}
.Scrollbar::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: gray;
}
.Scrollbar::-webkit-scrollbar-track {
    border-radius: 25px;
    background: white;
}

.ScrollbarThick::-webkit-scrollbar {
    width: 10px;
}
.ScrollbarThick::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: gray;
}
.ScrollbarThick::-webkit-scrollbar-track {
    border-radius: 25px;
    background: white;
}
