@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Pacifico&family=Montserrat:wght@400;500&display=swap);
.container > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    pointer-events: none;
    justify-content: start !important;
    font-family: 'Kanit', sans-serif;
    line-height: 0px;
    text-transform: uppercase;
}

.number {
    font-size: 450px;
    color: #373c4c;
}

.number span {
    display: inline-block;
    position: relative;
    transform: translate3d(-35%, 0, 0);
}

.header {
    margin-left: 350px;
    font-size: 70px;
    color: white;
}

.stripe {
    height: 2px;
    width: auto;
}

.slopeBegin {
    background-color: #20232f;
    -webkit-clip-path: polygon(20vw 0, 70% 0, calc(70% - 20vw) 100%, 0 100%);
            clip-path: polygon(20vw 0, 70% 0, calc(70% - 20vw) 100%, 0 100%);
}

.slopeEnd {
    -webkit-clip-path: polygon(70% 0, 100% 0, calc(100% - 20vw) 100%, calc(70% - 20vw) 100%);
            clip-path: polygon(70% 0, 100% 0, calc(100% - 20vw) 100%, calc(70% - 20vw) 100%);
}

.slopeBegin,
.slopeEnd {
    position: absolute;
    width: 140%;
    height: 100%;
    cursor: pointer;
}

.pink {
    background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
    background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
    background: linear-gradient(to right, tomato 0%, gold 100%);
}

.React_Select__control {
    font-family: Montserrat, sans-serif;
}

.React_Select__menu {
    font-family: Montserrat, sans-serif;
}




.Scrollbar::-webkit-scrollbar {
    width: 7px;
}
.Scrollbar::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: gray;
}
.Scrollbar::-webkit-scrollbar-track {
    border-radius: 25px;
    background: white;
}

.ScrollbarThick::-webkit-scrollbar {
    width: 10px;
}
.ScrollbarThick::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: gray;
}
.ScrollbarThick::-webkit-scrollbar-track {
    border-radius: 25px;
    background: white;
}

